<template>
	<!-- 新建一级页面 -->
	<div class="positionT0L0">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 3 }"
			:wrapper-col="{ span: 8 }">
			<a-form-model-item label="名称" prop="name" class="por">
				<a-input v-model="form.name" :maxLength="32">
					<div slot="addonAfter">{{ form.name.length }}/32</div>
				</a-input>
			</a-form-model-item>
			<a-form-model-item label="封面图" class="upload_img" :class="{ 'has-error': !form.cover }">
				<UpdataImg @avatarfns="data => form.cover = data[0]" :imageUrlNum="1" :imgUrl="form.cover ? [form.cover] : []"
					:urlSize="2048" />
			</a-form-model-item>
			<a-form-model-item label="宽度" prop="width" class="por">
				<a-input-number v-model="form.width" />
			</a-form-model-item>
			<a-form-model-item label="高度" prop="height" class="por">
				<a-input-number v-model="form.height" :min="0" />
			</a-form-model-item>
			<a-form-model-item label="分类" prop="typeS" :wrapper-col="{ span: 16 }">
				<a-radio-group name="radioGroup" v-model="form.typeS">
					<a-radio value="1"> 会员</a-radio>
					<a-radio value="2"> 课程</a-radio>
					<a-radio value="3"> 活动页</a-radio>
					<a-radio value="7"> 会员月</a-radio>
					<a-radio value="8"> 课程活动</a-radio>
				</a-radio-group>
				<Ueditor v-if="+form.typeS === 3 || +form.typeS === 7 || +form.typeS === 8" :content="form.description"
					@editorFn="val => form.description = val" />
			</a-form-model-item>
			<a-form-model-item v-if="form.typeS == 8" label="课程编号" prop="targetText" class="por">
				<a-input v-model="form.targetText" placeholder="请输入课程编号"></a-input>
			</a-form-model-item>
			<a-form-model-item label="排序" prop="name" class="por">
				<a-input v-model="form.sort" :maxLength="32"></a-input>
			</a-form-model-item>
			<a-form-model-item label="有效期" prop="datePicker">
				<a-range-picker v-model="form.datePicker" show-time format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate"
					:placeholder="['开始时间', '结束时间']" style="width: 410px;" @change="timeChange" />
			</a-form-model-item>
			<div v-show="+form.typeS === 3 || +form.typeS === 7">
				<a-form-model-item label="是否显示进入下一级按钮" prop="hasButton" :wrapper-col="{ span: 6 }" :label-col="{ span: 5 }">
					<a-radio-group name="radioGroup1" v-model="form.hasButton">
						<a-radio value="1"> 是</a-radio>
						<a-radio value="2"> 否</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item v-show="+form.hasButton === 1" label="按钮跳转类型" prop="buttonType" :wrapper-col="{ span: 6 }" :label-col="{ span: 3 }">
					<a-radio-group name="radioGroup2" v-model="form.buttonType">
						<a-radio :value="1">课程</a-radio>
						<a-radio :value="2">H5</a-radio>
						<a-radio :value="3">富文本</a-radio>
						<a-radio :value="4">会员开通页面</a-radio>
						<a-radio :value="5">小程序</a-radio>
						<a-radio :value="6">抖音</a-radio>
						<a-radio :value="7">首页读书会员页</a-radio>
						<a-radio :value="8">我的优惠券</a-radio>
						<a-radio :value="9">订单详情页</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item v-show="+form.hasButton === 1" label="按钮文字" prop="buttonText">
					<a-input v-model="form.buttonText" :maxLength="10">
						<div slot="addonAfter">{{ form.buttonText.length }}/10</div>
					</a-input>
				</a-form-model-item>
				<a-form-model-item v-show="+form.hasButton === 1" label="跳转链接" prop="buttonTarget">
					<a-input v-model="form.buttonTarget"></a-input>
				</a-form-model-item>
				<a-form-model-item v-show="+form.hasButton === 1" label="小程序跳转路径" prop="ButtonTargetPath">
					<a-input v-model="form.ButtonTargetPath" placeholder="请输入小程序跳转路径"></a-input>
				</a-form-model-item>
			</div>
			<a-form-model-item label="上架设置" prop="status">
				<a-radio-group v-model="form.status">
					<a-radio :style="radioStyle" value="1">立即上架</a-radio>
					<a-radio :style="radioStyle" value="2"> 暂不上架</a-radio>
				</a-radio-group>
			</a-form-model-item>
		</a-form-model>

		<FooterToolBar :collapsed="sideCollapsed">
			<a-button class="margin_right60" @click="() => $router.go(-1)">返回
			</a-button>
			<a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存
			</a-button>
		</FooterToolBar>
	</div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar";
import { AddSaveShopApi, GetCateDetailApi } from "@/request/api/indexManage";
import Ueditor from "@/components/ueditor/ueditor";
import { baseMixin } from "@/store/app-mixin";
import UpdataImg from "@/components/file/updataImg";
import { disabledDate } from '@/utils/tools'
import moment from "moment"

export default {
	mixins: [baseMixin],
	components: { FooterToolBar, Ueditor, UpdataImg },
	data () {
		return {
			disabledDate,
			keepLoading: false, // 保存的按钮是否加载
			radioStyle: {
				display: "block",
				height: "30px",
				lineHeight: "30px",
			},
			radioStyle11: {
				height: "30px",
				lineHeight: "30px",
			},
			form: {
				id: '',
				categoryNo: '',
				name: '', //
				sort: '', //排序
				typeS: 1, //分类
				description: '', // 富文本
				status: 1,
				hasButton: '0', //是否进入下一级按钮
				buttonText: '', //按钮文字
				buttonTarget: '', //跳转地址
				datePicker: [],
				width: 0,
				height: 0,
				startTime: '',
				endTime: '',
				cover: '',
				targetText: '',
				buttonType: 0,
				ButtonTargetPath: '',
			},
			rules: {
				status: [
					{
						required: true,
					},
				],
				hasButton: [
					{
						required: false,
					},
				],
				buttonText: [
					{
						required: false,
						message: "请输入按钮文字",
						trigger: "blur",
					},
				],
				buttonTarget: [
					{
						required: false,
						message: "请输入跳转地址",
						trigger: "blur",
					},
				],
				name: [
					{
						required: true,
						message: "输入的内容不能为空",
						trigger: "blur",
					},
					{
						max: 32,
						message: "长度最长为32个",
						trigger: "blur",
					},
				],

				typeS: [
					{
						required: true,
						message: "请选择分类",
						trigger: "change",
					},
				],
				datePicker: [{ required: true, message: '请选择有效期', trigger: ['change', 'blur'] }],
				targetText: [
					{
						required: true,
						message: "请输入课程编号",
						trigger: "blur",
					},
				]
			},
		};
	},
	created () {
		let id = this.$route.params.id;
		if (+id !== 0) {
			this.form.id = id
			this.getDetail()
		}
	},
	methods: {
		async getDetail () {
			const { code, data } = await GetCateDetailApi({ categoryNo: this.form.id })
			if (code !== 200) return
			const datePicker = [
				data.startTime,
				data.endTime
			]
			this.form.datePicker = datePicker
			this.form = Object.assign(this.form, data)
		},
		timeChange (times) {
			this.form.datePicker = times
			if (times.length === 0) return
			const start = moment(times[0]).format('yyyy-MM-DD HH:mm:ss')
			const end = moment(times[1]).format('yyyy-MM-DD HH:mm:ss')
			this.form.startTime = start
			this.form.endTime = end
		},
		// 保存的按钮
		keepClick () {
			this.keepLoading = true;
			this.$refs.ruleForm.validate((valid) => {
				this.keepLoading = false;
				if (!this.form.width) this.form.width = 0
				if (!this.form.height) this.form.height = 0
				if (valid) {
					AddSaveShopApi(this.form).then(({ code }) => {
						if (code == 200) {
							this.$message.success(`${+this.form.id > 0 ? '修改' : '添加'}成功`)
							this.$router.push('/indexManage/indexCategory')
						}
					});
				} else {
					this.$message.error("还有必填项未完成")
				}
			});
		}
	},
};
</script>

<style lang="less" scoped>
.form_span {
	top: -4px;
}

/deep/ .ant-radio-group {
	margin-bottom: 20px;
}

// /deep/ .ant-form-item-control {
// 	line-height: unset;
// }

.product-box {
	position: relative;
	width: 70%;
	border: 1px solid #ccc;
	border-radius: 4px;

	.product-close {
		position: absolute;
		top: -10px;
		right: -10px;
		cursor: pointer;
	}

	.product-wrap {
		height: 86px;

		.left-wrap {
			.product-img {
				margin-right: 10px;
				height: 86px;

				.p-img {
					height: 86px;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
			}

			.product-info {
				flex-direction: column;
				height: 86px;

				.p-title {
					margin-top: 6px;
					font-size: 14px;
					color: #000;
				}

				.p-price {
					color: red;
				}

				.p-type {
					color: #9fa5af;
					font-size: 12px;
					line-height: 18px;
				}
			}
		}

		.right-wrap {
			margin-left: 24px;
			margin-right: 24px;
		}
	}
}

/deep/ .product .ant-form-item-control {
	line-height: normal;
}

/deep/ .edu-type .ant-form-item-children {
	display: flex;
}

/deep/ .edu-type .ant-select:nth-child(2) {
	margin-left: 20px;
}

/deep/ .edu-quantity .ant-input-number {
	width: 100%;
}

/deep/ .date-picker .ant-form-item-children {
	display: flex;
	align-items: center;
}

// /deep/ .ant-form-item-control {
// 	line-height: 22px;
// }
</style>s
